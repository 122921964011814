import service from "@/api/service";
import { GetResponse } from "@/api/coupon-bg-image/response";
import { GetRequest, UploadRequest } from "@/api/coupon-bg-image/request";
import { CommonResponse } from "@/api/response";

/**
 * クーポン背景画像取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(request: GetRequest) {
  const response = await service.post("/get-coupon-bg-image", request);
  return response.data as GetResponse;
}

/**
 * クーポン背景画像登録APIをコールします。
 *
 * @param uploadRequest UploadRequest
 * @return CommonResponse
 */
export async function upload(uploadRequest: UploadRequest) {
  const response = await service.post("/upload-coupon-bg-image", uploadRequest);
  return response.data as CommonResponse;
}
