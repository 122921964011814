import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchItem, SearchResponse } from "@/api/coupon/response";
import { SearchRequest } from "@/api/coupon/request";
import * as CouponAPI from "@/api/coupon";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "coupon/search";

/**
 * クーポン一覧のデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem) {}
  get id() {
    return this._item.id;
  }
  get title() {
    return this._item.title;
  }
  get typeName() {
    return this._item.typeName;
  }
  get validityPeriod() {
    return this._item.validityPeriod;
  }
  get viewFlg() {
    return this._item.viewFlg == 1 ? "公開" : "非公開";
  }
  get publishStatus() {
    switch (this._item.publishStatus) {
      case 0:
        return "非公開";
      case 1:
        return "公開";
      case 2:
        return "公開予約";
      case 3:
        return "公開終了";
      default:
        return "";
    }
  }
  get previewUrl() {
    return this._item.previewUrl;
  }
  get actionFlg() {
    return this._item.actionFlg;
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  get deleteFlg() {
    return this._item.deleteFlg;
  }
}

/**
 * クーポン一覧検索API（/search-coupon）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchRequest: SearchRequest = {} as SearchRequest;
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getWriteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.writeFlg;
    } else {
      return false;
    }
  }

  get getDeleteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.deleteFlg;
    } else {
      return false;
    }
  }

  get getItems() {
    if (this.searchResponse.results) {
      // return this.searchResponse.results.items || [];
      return (this.searchResponse.results.items || []).map(
        value => new ListItem(value)
      );
    } else {
      return [];
    }
  }

  get getTotalCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.items.length || 0;
    } else {
      return 0;
    }
  }

  get getSearchRequest() {
    return this.searchRequest;
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await CouponAPI.search(searchRequest);
    SessionStorage.setObject(MODULE_NAME, searchRequest);
    return {
      searchRequest,
      searchResponse
    };
  }

  @MutationAction
  async restore(initial = {} as SearchRequest) {
    const searchRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as SearchRequest;
    return {
      searchRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
